<template>
  <a-dims-search-bar>
    <h1 class="inline-block text-black py-4">
      Historial
    </h1>

    <template #search-button>
      <a-logo-switch type="1" class="w-20 select-none lowercase" />
    </template>
  </a-dims-search-bar>
  <div class="flex py-16">
    <a-list
      :source="filterData(pagination.data)"
      :loading="loading"
      :should-emit-bottom-reached="pagination.next_page_url !== null"
      @bottom-reached="fetch({
      page: pagination.next_page_url ? pagination.current_page + 1 : pagination.current_page
    }, true)"
      #default="{ item }">
      <default-collaboration-cell :collaboration="item" class="pb-4">
<!--        <template #bottom="{ loading, toggleLoading }">-->
        <template>
          <div class="flex justify-between items-start mt-2">
            <span class="text-gray-500 bg-gray-200 py-0.5 px-2 font-medium">
              {{ statusParser(item.pivot.status) }}
            </span>
<!--            <button-->
<!--              v-if="shouldShowAcceptButton(item)"-->
<!--              :disabled="loading"-->
<!--              class="bg-primary focus:outline-none rounded-md text-white p-2 disabled:bg-gray-200"-->
<!--              @click.stop.prevent="accept(item, toggleLoading)">-->
<!--              <template v-if="!loading">-->
<!--                COLABORAR-->
<!--              </template>-->
<!--              <a-loader v-else color="text-gray-400" class="h-6 w-6 inline-block" />-->
<!--            </button>-->
          </div>
        </template>
      </default-collaboration-cell>
    </a-list>
  </div>

  <a-alert
    title="INVITACIÓN ACEPTADA"
    :show="modals.accepted !== null"
    @cancel="modals.accepted = null"
    @confirm="modals.accepted = null">
    <div class="space-y-3">
      <h1 class="text-lg">
        Gracias por aceptar la invitación de <b>@{{ modals.accepted?.restaurant?.ig_username }}</b>.
      </h1>
      <h1 class="text-lg">
        Llama al número <b class="text-primary">{{ modals.accepted?.restaurant?.phone }}</b> del restaurante para hacer tu reservación.
      </h1>
    </div>
  </a-alert>
</template>

<script>
import DefaultCollaborationCell from '../collaboration/index/old/v2/default-collaboration-cell'
import { mapState } from 'vuex'

export default {
  components: {
    DefaultCollaborationCell
  },
  data: () => ({
    pagination: { data: [] },
    loading: false,
    query: { limit: 15, with: 'restaurant', order_by: 'updated_at,desc', mode: 'history' },
    modals: {
      accepted: null
    },
    failed: false
  }),
  computed: mapState({
    user: ({ session }) => session.user
  }),
  methods: {
    fetch (segment = {}, appending = false) {
      this.loading = !this.loading
      const query = { ...this.query, ...segment }
      this.$repository
        .foodies
        .collaborations(this.user.foodie.id, query)
        .then(({ data: pagination }) => {
          this.pagination = appending
            ? { ...pagination, data: [...this.pagination.data, ...pagination.data] }
            : pagination

          this.query = query
        })
        .finally(() => (this.loading = !this.loading))
    },
    filterData (data) {
      return data.filter(value => {
        return value.pivot.status !== 'ignored'
      })
    },
    statusParser (status) {
      switch (status) {
        case 'canceled': return 'Cancelada'
        case 'accepted': return 'Aceptada'
        case 'ignored': return 'Ignorada'
        case 'completed': return 'Completada'
      }
    },
    shouldShowAcceptButton (item) {
      const isAnAcceptableStatus = ['canceled', 'ignored'].includes(item.pivot.status)
      const isClosed = item.closed_at !== null
      const hasUses = item.uses >= 1

      if (item.uses_limit === null) { // is private collaboration
        return !isClosed && isAnAcceptableStatus
      }

      return !isClosed && isAnAcceptableStatus && hasUses
    },
    accept (item, toggle) {
      toggle()
      this.$repository.collaborations
        .foodie(this.user.foodie.id)
        .accept(item.id)
        .then(() => {
          this.modals.accepted = item
          this.fetch({})
        })
        .catch(err => {
          this.failed = err.response?.data?.message === 'foodie_collab_limit_reached'
        })
        .finally(() => toggle())
    }
  },
  mounted () {
    this.fetch({})
  }
}
</script>
