<template>
  <component :is="`historial-${user.isFoodie ? 'foodies' : 'restaurants'}`"/>
</template>

<script>
import { mapState } from 'vuex'
import HistorialFoodies from '@/views/authenticated/foodies/historial/index.vue'
import HistorialRestaurants from '@/views/authenticated/restaurants/historial/index.vue'

export default {
  components: {
    HistorialFoodies,
    HistorialRestaurants
  },
  computed: mapState({
    user: ({ session }) => session.user
  })
}
</script>
