<template>
  <a-dims-search-bar
    placeholder="Buscar Foodie"
    hide-search
    @search="fetch({ search: $event })" >
    <router-link
      active-class="border-b-2 border-primary text-primary"
      to="/historial"
      class="inline-block py-4">
      Historial
    </router-link>

    <template #results>
      <div class="flex justify-center items-center flex-col" v-if="loading">
        <a-loader color="text-gray" class="h-10 w-10" />
        <h1 class="text-2xl text-white">Buscando</h1>
      </div>
    </template>
    <!-- <template #results>
      <p class="text-xl text-white text-left">Resultados</p>
      <div class="bg-white p-2 rounded-lg">
        <a-list
          :source="pagination.data"
          :loading="loading"
          #default="{ item, index }">
          <restaurant-cell
            @attach="attach(index)"
            @detach="detach(index)"
            :restaurant="item"
            :foodie="user.foodie.id" />
        </a-list>
      </div>
    </template> -->
  </a-dims-search-bar>
  <div class="flex grid-header padding-header">
    <div class="flex-grow">
    </div>
    <a-dropdown>
      <p class="text-left text-gray-400 px-2">Ordenar por</p>
      <a-dropdown-item @click="fetch({ sort_by: 'updated_at,desc' })">
        Mas recientes primero
      </a-dropdown-item>
      <a-dropdown-item @click="fetch({ sort_by: 'updated_at,asc' })">
        Mas recientes al final
      </a-dropdown-item>
    </a-dropdown>
  </div>
  <a-list
    class="flex flex-col"
    :source="pagination.data"
    :loading="loading"
    :should-emit-bottom-reached="pagination.next_page_url !== null"
    @bottom-reached="fetch({ page: pagination.next_page_url ? pagination.current_page + 1 : pagination.current_page }, true)"
    #default="{ item }">
    <GridElement :value="item"
                 class="px-normal mb-normal">
    </GridElement>
  </a-list>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import GridElement from './GridElement'

export default {
  name: 'Historial',
  components: {
    GridElement
  },
  data () {
    return {
      header: {
        type: 'historial'
      },
      pagination: { data: [] },
      modal: null,
      loading: false,
      query: {
        limit: 15,
        with: 'foodies',
        status: 'canceled,closed,completed',
        sort_by: 'updated_at,desc'
      }
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'session/isAuthenticated'
    }),
    ...mapState({
      user: ({ session }) => session.user
    })
  },
  methods: {
    update (collaboration, index) {
      this.pagination = {
        ...this.pagination,
        data: this.pagination.data
          .map(($0, $1) => $1 === index ? { ...$0, ...collaboration } : $0)
      }
    },
    fetch (segment = {}, appending = false) {
      this.loading = !this.loading
      const query = { ...this.query, ...segment }
      this.$repository.collaborations
        .index(query)
        .then(({ data: pagination }) => {
          this.pagination = appending
            ? { ...pagination, data: [...this.pagination.data, ...pagination.data] }
            : pagination
          this.query = query
        })
        .finally(() => (this.loading = !this.loading))
    }
  },
  mounted () {
    if (this.isAuthenticated) {
      this.fetch()
    }
  }
}
</script>

<style scoped>
@media screen and (min-width: 1000px){
  .gcp-restaurant-explore{
    padding-top: 66px;
  }
}
</style>
