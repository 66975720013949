<template>
  <div class="grid grid-cols-2 text-left border-b border-gray">
    <div @click="showCollaboration" class="cursor-pointer">
      <p class="font-regular text-lg text-primary font-medium">{{ (value?.type === 'private' ? 'Colab Privada' : 'Colab Abierta') + ' - ' + value.code }}</p>
      <!--        <p class="font-regular text-lg text-primary">Colab del {{ new Date(value.start_at).getDate() + '/' + new Date(value.start_at).getMonth() }}</p>-->
      <!--        <p class="font-light text-sm">Quedan {{ value.remaining_days}} dias · {{ getDiscount }} {{discountType}}</p>-->
      <p class="font-regular text-lowblack text-sm"><i class="icon-timer"></i>{{ `${value.start_at === value.end_at ? ' ' + getSpecifiedDate : ' ' + getDate}` }}</p>
      <p class="font-regular text-lowblack text-sm"><i class="icon-table"></i> {{ ' ' + value.uses }} Mesas reservadas</p>
      <p class="pt-2">Fecha de creación:</p>
      <p class="font-regular text-lowblack text-sm"><b>{{ getCreationDate }}</b></p>
      <p v-if="value.foodies" class="pt-2">Foodies invitados:</p>
      <div v-if="value.foodies" class="font-regular text-primary pb-2">
        <p v-for="foodie in value.foodies" :key="foodie">{{ `@${foodie.ig_username}`}}</p>
      </div>
    </div>
    <div class="text-gray text-right items-end">
      <div class="px-small inline-block">
        <p class="block text-lg font-medium text-red"
           :class="value?.status === 'canceled' ? 'text-red' : 'text-green-400'" >{{value?.status === 'canceled' ? ' Cancelada' : ' Completada'}}</p>
      </div>
      <div v-if="value?.foodies_meeting">
        <p class="font-light mt-1">Foodie quedada <i class="icon-userGroup"></i></p>
      </div>
      <div v-if="!value?.foodies_meeting && (value?.foodies.length > 1) && value?.type === 'private'">
        <p class="font-light mt-1">Múltiple<i class="icon-userGroup"></i></p>
      </div>
    </div>

  </div>
</template>

<script>

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'GridElement',
  props: {
    value: Object,
    gridType: String,
    multi: Boolean
  },
  data () {
    return {
      code: '',
      body: {
        attach: [],
        detach: []
      },
      selected: false,
      months: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
      ]
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'session/isAuthenticated'
    }),
    ...mapState({
      user: ({ session }) => session.user
    }),
    // getSpecifiedDate () {
    //   return new Date(this.value.end_at).getDate() + ' de ' +
    //     this.months[new Date(this.value.end_at).getMonth()] + ' ' +
    //     new Date(this.value.end_at).getFullYear() +
    //     ' a las ' + new Date(this.value.end_at).getHours() + ':' +
    //     String(new Date(this.value.end_at).getMinutes()).padStart(2, '0')
    // },
    getSpecifiedDate () {
      return this.$filters.moment(this.value.end_at, 'LL', true)
    },
    // getDate () {
    //   return new Date(this.value.start_at).getDate() + ' de ' +
    //     this.months[new Date(this.value.start_at).getMonth()] + ' ' +
    //     new Date(this.value.start_at).getFullYear() + ' al ' +
    //     new Date(this.value.end_at).getDate() + ' de ' +
    //     this.months[new Date(this.value.end_at).getMonth()] + ' ' +
    //     new Date(this.value.end_at).getFullYear()
    // },
    getDate () {
      return `${this.$filters.moment(this.value.start_at, 'LL', true)} al ${this.$filters.moment(this.value.end_at, 'LL', true)}`
    },
    getCreationDate () {
      return new Date(this.value.created_at).getDate() + ' de ' +
        this.months[new Date(this.value.created_at).getMonth()] + ' ' +
        new Date(this.value.created_at).getFullYear()
    }
  },
  methods: {
    showCollaboration () {
      this.$router.push({ path: `/collaborations/${this.value.id}` })
    }
  }
}
</script>

<style scoped>

img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
}
.icon-heartEmpty{
  color: #00000017;
  font-size: 18px;
}
.icon-heart{
  color: #FE4242;
  font-size: 18px;
}
.selected{
  @apply bg-primary text-white
}
.unselected{
  @apply bg-white border-gray text-white
}
.bg-selected{
  background-color: rgba(242, 180, 21, 0.1);
}
.bg-unselected {
  background-color: #ffffff;
}
.favorite {
  @apply text-red
}
.unfavorite {
  @apply text-gray
}
.testing-transition{
  transition: all 4s ease-in;
}
</style>
