<template>
  <li>
    <router-link :to="{ name: 'collaborations.show', params: { id: collaboration.id } }" class="px-4 block w-full">
      <div class="flex border-b py-2 items-center space-x-2">

<!--        <slot name="prefix" />-->

        <div class="flex flex-col flex-grow">
          <div class="flex justify-between items-start">
          <span class="font-bold text-primary text-left">
            <template v-if="collaboration.foodies_meeting">
              Foodiequedada con {{ collaboration.restaurant?.name }} - {{ collaboration.code }}
            </template>
            <template v-else>
              Colab con {{ collaboration.restaurant?.name }} - {{ collaboration.code }} ({{ collaboration.type === 'public' ? 'Abierta' : 'Privada' }})
            </template>
          </span>
          <span class="text-gray-500 bg-gray-200 py-0.5 px-2 font-medium">
            -{{ $filters.collaboration.discount(collaboration.discount_type, collaboration.discount_value) }}
          </span>
          </div>
          <div class="text-left">
<!--            <i class="icon-timer"></i> Del {{ $filters.moment(collaboration.start_at, 'DD/MM') }}-{{ $filters.moment(collaboration.end_at, 'DD/MM') }}-->
            <i class="icon-timer"></i>{{ `${collaboration.start_at === collaboration.end_at ? ' Fecha: ' + getSpecifiedDate : ' ' + getDate}` }}
          </div>
          <div class="flex justify-between">
            <span>
              <i class="icon-table text-lg align-middle" /> {{ collaboration.uses }} mesas reservadas
            </span>
              <span class="text-gray-400" v-if="collaboration.uses_limit">
              {{ collaboration.uses }}/{{ collaboration.uses_limit }} cupos canjeados <i class="icon-countdown text-lg align-middle" />
            </span>
          </div>
          <slot name="bottom" v-bind="{ loading, toggleLoading }" />
        </div>
<!--        <slot name="suffix" />-->
      </div>
    </router-link>
  </li>
</template>

<script>
import { ref } from 'vue'
export default {
  props: {
    collaboration: { required: true, type: Object }
  },
  data () {
    return {
      months: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
      ]
    }
  },
  setup () {
    const loading = ref(false)

    const toggleLoading = () => {
      loading.value = !loading.value
    }

    return {
      loading,
      toggleLoading
    }
  },
  computed: {
    // getSpecifiedDate () {
    //   return new Date(this.collaboration.end_at).getDate() + ' de ' +
    //     this.months[new Date(this.collaboration.end_at).getMonth()] + ' ' +
    //     new Date(this.collaboration.end_at).getFullYear() +
    //     ' a las ' + new Date(this.collaboration.end_at).getHours() + ':' +
    //     new Date(this.collaboration.end_at).getMinutes()
    // },
    getSpecifiedDate () {
      return this.$filters.moment(this.collaboration.end_at, 'LL', true)
    },
    // getDate () {
    //   return new Date(this.collaboration.start_at).getDate() + ' de ' +
    //     this.months[new Date(this.collaboration.start_at).getMonth()] + ' ' +
    //     new Date(this.collaboration.start_at).getFullYear() + ' al ' +
    //     new Date(this.collaboration.end_at).getDate() + ' de ' +
    //     this.months[new Date(this.collaboration.end_at).getMonth()] + ' ' +
    //     new Date(this.collaboration.end_at).getFullYear()
    // },
    getDate () {
      return `${this.$filters.moment(this.collaboration.start_at, 'LL', true)} al ${this.$filters.moment(this.collaboration.end_at, 'LL', true)}`
    },
    getCreationDate () {
      return new Date(this.collaboration.created_at).getDate() + ' de ' +
        this.months[new Date(this.collaboration.created_at).getMonth()] + ' ' +
        new Date(this.collaboration.created_at).getFullYear()
    }
  }
}
</script>
